.w_home-banner-section{
    height: 100vh;
    background-color: #fff;
    .w_inner{
        height: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
    }
    .w_panel{
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        padding: 100px 30px 30px 30px;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6530813008797269) 75%, rgba(255,255,255,0.14887962020745793) 95%, rgba(255,255,255,0) 100%);
    }
    .w_slick-button-fill{
        margin-top: 30px;
        width: 140px;
    }
    @media(min-width: $laptop){
        margin-top: 134px;
        height: calc(100vh - 134px);
        .w_inner{
            @include boxLayout2;
        }
        .w_panel{
            background: #fff;
            padding: 60px 30px 60px 0;
            width: auto;
            text-align: left;
            bottom: unset;
            top: 50%;
            transform: translateY(-50%);
        }
        .w_heading{
            margin:20px 0 0 0;
        }
        .w_sub-heading{
            margin-top: 7px;
        }
        .w_slick-button-fill{
            margin-top: 30px;
            @include slickAnimationWiderButton;         
        }
    }
    @media(min-width: $desktop){
        max-height: 650px;
        .w_inner{
            background-position: center right;
            background-size: 86% auto;
            background-repeat: no-repeat;
        }
    }
}

.w_section {
    .w_heading-column{
        width: 100%;
        margin-bottom: 100px;
    }
}

.w_section-home-1{
    text-align: center;
    h3{
        margin: 0;
        z-index: 1;
        position: relative;
        max-width: 800px;
        margin: 0 auto;
    }
    .w_img{
        display:none;
        @media(min-width: $laptop){
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: calc(100% + 100px);
            z-index: 0;
        }
    }
}

.w_link-column-section{
    position: relative;
    .w_link-inner{
        display: flex;
        flex-wrap: wrap; 
        height: 100%;      
        .w_link-column{
            width: 100%;
            position: relative;
            margin-bottom: 0;
            height: 0;
            padding-bottom: 60%;
            background-position: center;
            background-size: cover;
            z-index: 1;
            &:last-child{
                margin-bottom: 0;
            }
            .w_link-heading{
                color: #fff;
                position: absolute;
                top: calc(50% - 20px);
                left: 50%;
                transform:translate(-50%, -50%);
                text-transform: uppercase;
            }
            .w_btn {
                display:none;
            }
            .w_overlay{
                background-color: rgba(0, 0, 0, 0.35);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
        @media(min-width: $laptop){
            flex-wrap: nowrap;
            padding-left: 0;
            padding-right: 0;
            .w_overlay{
                display: none;
            }
            .w_link-column{
                background-image: none!important;
                @include transition(.25s);               
                margin-bottom: 0;
                padding-bottom: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.35);
                .w_btn {
                    display:block;
                    position: absolute;
                    left: calc(50% - 20px);
                    bottom: 60px;
                    transform:translateX(-50%);
                    background-color: transparent;
                    color: #fff;
                    opacity: 0;

                }
                .w_slick-button{
                    @include slickAnimationWiderButton; 
                }
                &:hover{
                    background-color: rgba(0, 0, 0, 0.80);
                    .w_btn{
                        opacity: 1;
                        left: 50%;
                    }
                }
            }            
        }
    }
    // @media(max-width: $laptop - 1px){
    //     @include whiteBorderSection;
    // }
    @media(min-width: $laptop){
        height: calc(100vh - 134px);
        .w_bg-image{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-position: center;
            background-size: cover;
        }
    }
}

.w_get-in-touch-link-section{
    text-align: center;
    background-color: $themeGrey;
    margin-bottom: 15px;
    margin-top: 15px;
    .w_inner{
        justify-content: center;
    }
    h3{
        width: 100%;
    }
    .w_btn{
        margin: 0 auto;
        display: block;
    }
    .w_slick-button{
        @include slickAnimationWiderButton;
    }
}



.w_about-top-section{
    margin-top: 30px;
    img{
        width: 100%;
    }
    .w_heading{
        margin-top: 0;
    }
    .w_above-heading{
        margin-top: 60px;
        margin-bottom: 0;
    }
    .w_section-heading{
        margin-top: 15px;
    }
    .w_btn.w_slick-button{
        @include slickAnimationWiderButton;
    }
    .w_img{
        width: 100%;
        padding-bottom: 120%;
        background-position: center;
        background-size: cover;
        margin-top: 100px;

    }
    @media(min-width: $laptop){
        margin-top: 100px;
        .w_inner{
            justify-content: space-between;
        }
        img{
            max-width: 370px;
        }
        .w_img{
            margin-left:calc(100% - 50vw);
            width: 50vw;
            padding-bottom: 147%;
            
        }
        .w_column {
            padding-right: 0;
            &:last-child{
                max-width: 470px;
                padding-left: 30px;
            }
        }
    }
}

.w_section-bout-2{
    padding-top: 0;
    padding-bottom: 0;
    .w_inner{
        justify-content: center;
    }
    .w_img{
        width: 100%;
        margin-bottom: 30px;
        img{
            width: 100%;
        }
    }
    @media(min-width: $laptop){
        .w_img{
            width: 50%;
            margin-bottom: 0;
            &:nth-of-type(1){
                padding-right: 15px;
            }
            &:nth-of-type(2){
                padding-left: 15px;
            }
        }
    }
}

.w_section-bout-3{
    text-align: center;
    .w_inner{
        justify-content: center;
    }
    .w_item{
        max-width: 770px;
    }
    h2{
        margin-top: 0;
    }
}

.w_section-sercices-1{
    margin-top: 100px;
    .w_column {
        padding-top: 0;
        padding-bottom: 0;
    }
    .w_inner{
        align-items: flex-start;
    }
    h1{
        width: 100%;
        margin-top: 0;
    }
    .w_copy{
        p{
            &:nth-of-type(1){
                margin-top: 0;
            }
        }
    }
}



.w_section-contact-page{
    margin-top: 100px;
    .w_inner{
        align-items: flex-start;
    }
    .w_column{
        padding-top: 0;
    }
    p{
        color: $themeDark;
    }
    .w_flex{
        &:nth-of-type(1){
            padding-bottom: 30px;
        }
        .w_item{
            padding: 0 5px;
            &:nth-of-type(1){
                padding-left: 0;
            }
        }
        p{
            padding: 0 15px;
            margin: 0;
            &:nth-of-type(1){
                padding-left: 0;
            }
        }
    }
    
    .w_embedded-map{
        overflow: hidden;
        position: relative;
        padding-bottom: 75%;
        height: 0;
        .w_iframe{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
    }
    @media(min-width: $laptop){
        .w_embedded-map{
            padding-bottom: 120%;
        }
        .w_column{
            &:nth-of-type(2){
                padding-right: 0;
                padding-left: 100px;
            }
        }
    }
}

.w_section-signin-page{
    margin-top: 100px;
    
    .w_inner{
        justify-content: center;
    }
    .w_column{
        padding: 0;
    }
}

.w_section-thankyou-page{
    margin-top: 100px;
    .w_heading-column{
        width: 100%;
        margin-bottom: 100px;
    }
    .w_inner{
        justify-content: center;
    }
    .w_column{
        padding: 0;
    }
    .w_slick-button-fill{
        @include slickAnimationWiderButton;
    }
}

.w_section-dashboard{
    margin-top: 100px;
    padding-bottom: 30px;
     min-height: calc(100vh - 155px);
    .w_heading-column{
        width: 100%;
        margin-bottom: 100px;
    }
    .w_inner{
        justify-content: center;
    }
    .w_column{
        padding: 0;
    }
    .w_column {
        margin-bottom: 30px;
        .w_btn{
            margin-top: 0;
        }
    }
}

.w_items-section{
    padding-top: 0;
}

.w_section-projects{
    margin-top: 100px;
    .w_column {
        padding-top: 0;
        padding-right: 0;
    }
    
    .w_item{
        width: 100%;
        margin-bottom: 60px;
    }
    img{
        width: 100%;
    }
    h3{
        margin-bottom: 10px;
    }
    .w_btn{
        margin-top: 20px;
    }
    .w_slick-button{
        @include slickAnimationWiderButton;
    }
    @media(min-width: $laptop){
        .w_items-contailer{
            display: flex;
            margin: 0 -12px;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .w_item {
            padding-top: 0;
            width: 33.3333%;
            padding: 0 15px;
        }
        
    }
}

.w_section-social-feed-page{
    margin-top: 100px;
    margin-bottom: 100px;
    .w_column{
        padding-top: 0;
    }
    .w_inner{
        flex-wrap: wrap;
    }
    .w_heading-column{
        margin-bottom: 0;
    }
}
.w_social-feed{
    min-height: 80vh;
}

.w_section-project{
    margin-top: 0;
    .w_inner{
        justify-content: center;
    }
    .w_column-project-details{
        padding: 0;
        width: 100%;
        max-width: 770px;
        img{
            width: 100%;
        }
        .w_heading{
            text-align: center;
        }
        .w_small-heading{
            text-align: center;
            margin-bottom: 30px;
        }
        .w_copy{
            text-align: center;
            margin-top: 60px;
        }
    }
}

.w_spit-section-2{
    position: relative;
    clear: right;
    margin:60px 0;
    img{
        width: 100%;
    }
    .w_column {
        margin-top: 60px;
        padding-top: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    .w_section-heading{
        margin-top: 0;
    }
    .w_slick-button{
        @include slickAnimationWiderButton;
    }
    @media(min-width: $laptop){
        margin:100px 0;
        &.w_last{
            margin-bottom: 0;
        }
        img{
            width: 50%;
            float: right;
            clear: right;
        }
        .w_inner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform:translate(-50%, -50%);
            width: calc(100% - 60px);
            max-width: 1144px;
            height: calc(100% - 200px);
            max-height: 650px;
        }
        .w_column{
            margin-top: 0;
            padding-left: 0;
            height: 100%;
            overflow-y: auto;
            width: calc(50% - 30px);
            .w_column-inner{
                max-height: 100%;
            }
        }
        &.w_column-right{
            img{
                float: none;
            }
            .w_column{
                float: right;
                padding-left: 100px;
                padding-right: 0;
                width: 50%;
            }
        }
    }
}

.w_section-project-top{
    margin-top: 0;
    margin-bottom: 100px;
    .w_inner{
        .w_heading{
            margin-bottom: 30px;
        }
    }
    .w_column{
        
        img{
            width: 100%;
        }
    }
    
    @media(min-width: $laptop){
        margin-top: 100px;
        .w_column{
            padding-right: 0;
            img{
                width: calc(100% - 100px);
            }
            .w_column-inner{
                max-height: 500px;
                overflow: auto;
            }
        }
    }
}

.w_item-video{
    width: 100%;
    position: relative;
    padding-bottom: 56.3%;
    height: 0;
    video{
        position: absolute;
        left:0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.w_item-img{
    width: 100%;
    img{
        width: 100%;
        display: block;
    }
}

.w_dashboard-gallery-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .w_img{
        width: 50%;
        padding: 15px;
        &:nth-of-type(2n+1){
            padding-left: 0;
        }
        &:nth-of-type(2n+0){
            padding-right: 0;
        }
        img{
            width: 100%;
        }
    }
    @media(min-width: $laptop){
        .w_img{
            width: 25%;
            padding: 15px;
            &:nth-of-type(2n+1){
                padding-left: 15px;
            }
            &:nth-of-type(2n+0){
                padding-right: 15px;
            }
            &:nth-of-type(4n+1){
                padding-left: 0;
            }
            &:nth-of-type(4n+0){
                padding-right: 0;
            }
        }
    }
}

.w_dropzone{
    width: 100%;
    height: 200px;
    text-align: center;
    border: 2px solid $themeGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.w_section-pagenotfound{
    margin-top: 100px;
    min-height: calc(100vh - 350px);
    .w_inner{
        justify-content: center;
        .w_heading{
            width: 100%;
            text-align: center;
        }
        .w_copy {
            width: 100%;
            text-align: center;
            p{
                margin: 0;
            }
        }
        .w_btn {
            margin-top: 60px;
            @include slickAnimationWiderButton;
        }
    }
}

.w_section-sercices-2{
    margin-top: 100px;
    margin-bottom: 30px;
}

.w_section-information{
    margin-top: 100px;
    min-height: calc(100vh - 350px);
    .w_inner{
        justify-content: center;
        .w_heading{
            width: 100%;
            text-align: left;
            margin-bottom: 60px
        }
        .w_copy {
            width: 100%;
            text-align: left;
            p{
                // margin: 0;
            }
        }
        .w_btn {
            margin-top: 60px;
            @include slickAnimationWiderButton;
        }
        img{
            max-width: 100%;
        }
        .w_logo{
            width: 300px;
        }
    }
}