.w_header{
    position:fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 2;
    @include transition(0.5s);
    @include transitionDelay;
    background-color: #fff;
    .w_logo{
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
        z-index: 1;
        @include transition(0.5s);
        @include transitionDelay;
        img{
            width: 80px;
            @include transition(0.5s);
            @include transitionDelay;
        }
        .w_widescreen{
            display: none;
            @media(min-width: $laptop){
                display: block;
            }
        }
        .w_mobile{
            @media(min-width: $laptop){
                display: none;
            }
        }
    }
    .w_menu-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    .w_slick-menu-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    @media(min-width: $laptop){
        background-color: #fff;
        height: 134px;
        .w_inner{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include boxLayout;
        }
        .w_logo{
            position: unset;
            transform: none;
            background-color: transparent;
            padding: 20px 15px;
            img{
                width: 170px;
            }
        }
        .w_menu-icon{
            display: none;
        }
        .w_slick-menu-icon{
            display: none;
        }
        &.w_transformed{
            .w_logo{
                padding: 10px 0;
            }
        }
    }
    &.w_transformed{
        @media(min-width: $laptop){
            height: 92px;
            .w_logo{
                padding: 20px 15px 15px 15px;
                img{
                    width: 100px;
                }
            }
        }
    }
}

.w_mobile-nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50px;
    visibility: hidden;
    opacity: 0;
    @include transition(0.5s);
    &.w_open{
        left: 0;
        visibility: visible;
        opacity: 1;
    }
    ul{
        li{
            text-align: center;
            &.w_nav-link{
                a{
                    padding: 14px 0;
                    font-size: 20px;
                    font-weight: 400;
                }
            }  
            .w_slick-button-fill{
                margin-top: 22px;
            }          
        }
    }
    @media(min-width: $laptop){
        display: none;
    }
}

.w_widescreen-nav{
    display: none;
    @media(min-width: $laptop){
        display: block;
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 15px;
            .w_nav-link{
                a{
                    padding: 12px 20px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .w_button{
                margin-left: 20px;
            }
        }
    }
}
.w_footer{
    background-color: $themeDark;
    padding: 0;
    // margin-bottom: 15px;
    a{
        color: #fff;
        font-size: 14px;        
        @include transition(.05s);        
        @media(min-width:$laptop){
            opacity: 0.8;
            &:hover{
                opacity: 1;
            }
        }
    }
    .w_inner{
        @include boxLayout;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        &:nth-of-type(1){
            .w_footer-item{
                &:nth-of-type(1){
                    width: 100%;
                    h3{
                        max-width: 230px;
                        margin: 0;
                    }
                }
                &:nth-of-type(2){
                    width: 50%;
                }
                &:nth-of-type(3){
                    width: 50%;
                }
                &:nth-of-type(4){
                    width: 100%;
                }
                @media(min-width: $tablet){
                    &:nth-of-type(1){
                        width: 44%;
                        h3{
                            max-width: 260px;
                        }
                    }
                    &:nth-of-type(2){
                        width: 28%;
                    }
                    &:nth-of-type(3){
                        width: 28%;
                    }
                    &:nth-of-type(4){
                        width: 100%;
                    }
                }
                @media(min-width: $laptop){
                    &:nth-of-type(1){
                        width: auto;
                        h3{
                            max-width: 280px;
                        }
                    }
                    &:nth-of-type(2){
                        width: auto;
                    }
                    &:nth-of-type(3){
                        width: auto;
                    }
                    &:nth-of-type(4){
                        width: auto;
                    }
                }
                @media(min-width: $desktop){
                    &:nth-of-type(1){
                        width: auto;
                        h3{
                            max-width: 310px;
                        }
                    }
                }
            }
            
        }
        &:nth-of-type(2){
            padding-bottom: 85px;
            .w_footer-item{
                padding: 15px;
                &:nth-of-type(1){
                    width: 100%;
                }
                &:nth-of-type(2){
                    width: 100%;
                }
                &:nth-of-type(3){
                    width: 100%;
                }
                &:nth-of-type(4){
                    width: 100%;
                }
                @media(min-width: $tablet){
                    &:nth-of-type(1){
                        width: 50%;
                    }
                    &:nth-of-type(2){
                        width: 50%;
                    }
                    &:nth-of-type(3){
                        width: 50%;
                    }
                    &:nth-of-type(4){
                        width: 50%;
                    }
                }
                @media(min-width: $laptop){
                    &:nth-of-type(1){
                        width: auto;
                    }
                    &:nth-of-type(2){
                        width: auto;
                    }
                    &:nth-of-type(3){
                        width: auto;
                    }
                    &:nth-of-type(4){
                        width: auto;
                    }
                }
            }
        }
    }
    .w_footer-item{
        padding: 15px;
    }
    .w_footer-links{
        padding-left: 30px;
        position: relative;
        &:before{
            content: "";
            display: block;
            width: 1px;
            background-color: $themeOrange;
            height: 84%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .w_footer-link{
        text-transform: uppercase;
        a{
            padding: 3px 0;
            display: block;
        }
    }
    .w_social-media-links{
        display: flex;
        justify-content: flex-start;
        a{
            display: block;
            margin-right: 15px;
        }
        img{
            width: 30px;
            height: 30px;
        }
    }
    .w_contact-link{
        span{
            color: $themeOrange;
            font-size: 14px;
        }
    }
    .mba_logo{
        width: 170px;
    }
}

.w_footer-bottom{
    background-color: #fff;
    padding: 30px;
    p{
        margin-top: 0;
        font-size: 14px;
        color: $themeDark;
        text-align: center;
    }
    a{
        color: $themeDark;
        @include transition(.1s);
        &:hover{
            text-decoration: underline;
        }
    }
    @media(min-width: $laptop){
        padding: 45px 30px;
    }
}

.w_section{
    padding: 0;
    position: relative;
    .w_inner{
        @include boxLayout2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
    &.w_column-right{
        .w_inner{
            justify-content: flex-end;
        }
        .w_column{
            padding-right: 0;
            @media(min-width: $laptop){
                padding-left: 30px;
            }            
        }
    }
}

.w_column{
    width: 100%;
    padding-top: 90px;
    
    .w_btn{
        margin-top: 60px;
    }
    @media(min-width: $laptop){
        width: 50%;
        padding-top: 0;
        padding-right: 30px;
        .w_above-heading{
            margin-bottom: 30px;
        }        
        .w_column-inner{
            max-height: 30vw;
            overflow-y: auto;
            padding-right: 10px;
        }
        h2{
            margin-top: 0;
        }
        .w_btn{
            margin-top: 40px;
        }
    }
}

.w_image-column{
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -90px;
    img{
        width: 100%;
    }
    @media(min-width: $laptop){
        width: calc(50vw - 8.8px);
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
        z-index: 0;
        margin-top: 0;
        img{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        &.w_right{
            right:0;
            left: unset;
            img{
                left: unset;
                right: 0;
            }
        }
    }
}