@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

@import 'variables';
@import 'mixins';
@import 'global';
@import 'typography';
@import 'layout';
@import 'pages';
@import 'ui';
@import 'transitions';

.crt-logo{
    display: none!important;
}