html {
  box-sizing: border-box;
  // scroll-behavior: smooth;
}
*{
  font-family: 'Montserrat', sans-serif;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
    margin: 0;
    color: $themeDark;
}
strong{
  font-weight: 600;
}
p, li, td, input, textarea{
  font-size: 16px;
}
p{
  margin-top:18px;
  margin-bottom: 18px;
  line-height: 26px;
  &:last-child{
    margin-bottom: 0;
  }
}
a{
  text-decoration: none;
  color: inherit;
}

ul{
  padding-left: 0;
}
li{
  list-style: none;
}

figure{
    margin: 0;
}

