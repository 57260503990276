@mixin buttonStyle{
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    padding: 10px 20px;
    border: 1px solid $themeOrange;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}

@mixin buttonStyleSmall{
    font-size: 12px;
    line-height: 16px;
    height: 30px;
    padding: 6px 3px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    min-width: 50px;
}

@mixin transition($speed: .25s){
    transition: $speed;
    -webkit-transition: $speed;
    -moz-transition: $speed;
    -ms-transition: $speed;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
}

@mixin transitionDelay($speed: .25s){
    transition-delay: $speed;
    -webkit-transition-delay: $speed;
    -moz-transition-delay: $speed;
    -ms-transition-delay: $speed;
}

@mixin boxLayout{
    width: calc(100% - 30px);
    max-width: 1170px;
    margin: 0 auto;
}

@mixin boxLayout2{
    width: calc(100% - 60px);
    max-width: 1144px;
    margin: 0 auto;
}

@mixin slickAnimation{
    display: inline-block;
    width: 100px; 
    position: relative;
    padding: 11px 0;
    height: 40px;
    text-align: center;
    font-size: 14px;
    @include transition(.55s);
    border: none;
    cursor: pointer;
    svg {
        height: 40px;
        left: 0;
        position: absolute;
        top: 0; 
        width: 100%; 
    }
    
    rect {
        fill: none;
        stroke: $themeOrange;
        stroke-width: 3;
        stroke-dasharray: 422, 0;
        @include transition(.6s);
    }
    @media(min-width: $laptop){
        &:hover{
            background-color: transparent;
            color: $themeDark;
            rect {
                stroke-width: 4;
                stroke-dasharray: 38, 310;
                stroke-dashoffset: 177;
                @include transition(.6s);
            }
        }
    }
}

@mixin headingStyles{
    font-size: 36px;
    line-height: 40px;
    font-weight: 300;
    @media(min-width: $tablet){
        font-size: 38px;
        line-height: 43px;
    }
    @media(min-width: $laptop){
        font-size: 40px;
        line-height: 46px;
        
    }
    @media(min-width: $desktop){
        line-height: 49px;
    }
}

@mixin spanOpen{
    &:nth-of-type(1){
        top: 10px;
        left: 0px;
        opacity: 1;
    }
    &:nth-of-type(2){
        bottom: 10px;
        right: 0px;
        opacity: 1;
    }
    &:nth-of-type(3){
        top: 0px;
        left: -10px;
        transform: rotate(45deg);
        opacity: 0;
    }
    &:nth-of-type(4){
        top: 0px;
        right: -10px;
        transform: rotate(-45deg);
        opacity: 0;
    }
}

@mixin spanClose{
    &:nth-of-type(1){
        top: 10px;
        left: -10px;
        opacity: 0;
    }
    &:nth-of-type(2){
        bottom: 10px;
        right: -10px; 
        opacity: 0;
    }
    &:nth-of-type(3){
        top: 13px;
        left: 0;
        opacity: 1;
    }
    &:nth-of-type(4){
        top: 13px;
        right: 0;
        opacity: 1;
    }
}

@mixin slickAnimationWiderButton{
    width: 140px;
    @media(min-width: $laptop){
        &:hover{
            rect {
                stroke-dashoffset: 117;
            }
        }
    }
}

@mixin whiteBorderSection{
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 30px);
}   