$themeDark: #212529;
$themeLightDark: #8E8E8E;
$themeOrange: #EA642B;
$themeGrey: #D1D3D5;
$themeLight: #E0E0E0;
$themeExtraLight: #F4F3F3;
$themeDarkGrey: #808080;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;