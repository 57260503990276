.w_heading{
    @include headingStyles;
    @media(min-width: $tablet){
        font-weight: 400;
    }
}

.w_section-heading{
    @include headingStyles;    
}

.w_sub-heading{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    @media(min-width: $tablet){
        font-size: 19px;
        line-height: 23px;
        font-weight: 400;
    }
    @media(min-width: $laptop){
        font-size: 20px;
        line-height: 24px;
    }
    @media(min-width: $desktop){
        font-size: 20px;
        line-height: 24px;
    }
}

.w_small-heading{
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
}

.w_small-section-heading{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
}

.w_medium-heading{
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
    @media(min-width: $tablet){
        font-size: 25px;
        line-height: 33px;
    }
    @media(min-width: $laptop){
        font-size: 27px;
        line-height: 37px;
    }
    @media(min-width: $desktop){
        font-size: 30px;
        line-height: 40px;
    }
}

.w_link-heading{
    @include headingStyles;
}