.w_button{
    color: #fff;
    background-color: $themeOrange;
    @include buttonStyle;
    @include transition(0.1s);
    @media(min-width: $laptop){
        opacity: 0.9;
        &:hover{
            opacity: 1;
        }
    }
}

.w_button-white{
    background-color: #fff;
    @include buttonStyle;
    @include transition;
    @media(min-width: $laptop){
        &:hover{
            color: #fff;
            background-color: $themeOrange;
        }
    }
}

.w_button-small{
    background-color: #fff;
    @include buttonStyleSmall;
    @media(min-width: $laptop){
        opacity: 0.8;
        &:hover{
            opacity: 1;
        }
    }
}

.w_above-heading{
    display: inline-block;
    position: relative;
    margin-bottom: 30px;
    h4{
        margin: 0;
    }
    &:after{
        content:"";
        width: 54px;
        height: 2px;
        background-color: $themeOrange;
        position: absolute;
        top: 7px;
        right: -63px;
    }
}

.w_menu-icon{
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    span{
        position: relative;
        display: block;
        height: 100%;
        &:before{
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            top: 38%;
            left: 0;
            background-color: $themeOrange;
            @include transition;
        }
        &:after{
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            top: 62%;
            left: 0;
            background-color: $themeOrange;
            @include transition;
        }
    }
    &.w_close{
        span{
            &:before{                
                top: 50%;
                transform: rotate(45deg);
            }
            &:after{
                top: 50%;
                transform: rotate(-45deg);
            }
        }
    }
}

.w_slick-menu-icon{
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    overflow: hidden;
    .w_inner{
        position: relative;
        height: 100%;        
        span{
            display: block;
            width: 100%;
            height: 2px;
            background-color: $themeOrange;
            position: absolute;
            @include transition(.35s);
            border-radius: 100px;
            @include spanOpen;
        }
    }
    &.w_close{
        span{
            @include spanClose;
        }
    }
}

.w_nav-links{
    padding-left: 0;
    li{
        list-style: none;
        a{
            display: inline-block;
        }
    }
    .w_nav-link{
        a{
            @media(min-width:$laptop){
                &:after{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 2px;
                    background-color: $themeOrange;
                    margin: 0 auto;
                    position: relative;
                    left: -10px;
                    top: 10px;
                    @include transition;
                    opacity: 0;
                }
                &:hover, &.active{
                    &:after{
                        left: 0;
                        opacity: 1;
                    }
                }
            }   
            &.active{
                &:after{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 2px;
                    background-color: $themeOrange;
                    margin: 0 auto;
                    position: relative;
                    left: 0;
                    top: 10px;
                    opacity: 1;
                }
            }         
        }
    }
}

.w_slick-button {
    color: $themeDark;  
    background-color: transparent;
    @include slickAnimation;
}

.w_slick-button-fill {
    color: #fff;    
    background: $themeOrange;     
    @include slickAnimation;
}

.w_section-white-border{
    @include whiteBorderSection;
}

.w_spit-section{
    @media(min-width: $laptop){
        .w_column {
            max-width: 470px;
        }
    }
}

.w_form-group,
.w_copy ul,
ul.w_list-style{
    li{
        padding-left: 20px;
        position: relative;
        line-height: 26px;
        color: $themeDarkGrey;
        &:before{
            content: "";
            background-color: $themeOrange;
            font-weight: 700;
            position: absolute;
            left: 0;
            top: 13px;
            width: 5px;
            height: 2px;
        }
    }
    // @media(min-width: $laptop){
    //     max-height: 140px;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     flex-wrap: wrap;
    //     overflow: hidden;
    // }
}

.w_form-group,
.w_copy ol,
ol.w_ordered-list-style{
    padding-left: 20px;
    li{
        list-style: inherit;
        color: $themeLightDark;
        position: relative;
        padding-left: 10px;
        line-height: 26px;

        &::marker{
            color: $themeOrange;
            font-weight: 700;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.w_inner{
    .w_heading{
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.w_orange-text-400{
    color: $themeOrange;
    font-weight: 400;
}

.w_flex{
    display: flex;
}

.w_horline{
    width: 100%;
    background-color: $themeLight;
    height: 1px;
    margin: 30px 0;
}

.w_form{
    label{
        display: block;
        margin-bottom: 10px;
    }
    
    input, select{
        height: 38px;
    }
    textarea{
        height: 200px;
        padding-top:10px;
    }
    
}

.w_form-group{
    margin-bottom: 30px;
}

.w_form-control{
    width: 100%;
    border: 1px solid $themeLight;
    padding-left: 10px;
    
    font-weight: 400;
    color: $themeDark;
    font-size: 14px;
}

.w_error-message{
    font-size: 12px;
    margin-top: 5px;
    color: red;
    font-weight: bold;
}

.w_section-contact-page p.w_error-message-after,
.w_error-message-after{
    font-size: 18px;
    margin-top: 5px;
    color: red;
    font-weight: bold;
}
.w_message-sending-message{
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
}
.w_error{
    .w_form-control{
        border-color: red;
    }
}
.w_form-errors{
    margin-bottom: 30px;
}
.w_button-group{
    display: flex;
    justify-content: space-between;
    .w_item{
        .w_btn{
            margin-top: 0;
        }
    }
}

.w_message-gif{
    width: 280px;
    height: 200px;
    margin-top: -75px;
    margin-right: -100px;
}


.w_submit-bottons-slick-group{
    position: relative;
    margin-bottom:60px;
    .w_slick-button {
       
        &.w_hide{
            visibility: hidden;
            opacity: 0;
        }
    }
    .w_slick-button-submitted{
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        transition: 1s;
        height: 40px;
        width: 100px;
        transition: 0.5s;
        background-color: #fff;
        border: 2px solid transparent;
        
        .w_inner-btn{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 9px 0;
            text-align: center;
            font-size: 14px;
            span{
                position: absolute;
                width: 10px;
                height: 10px;
                display: block;
                transition: 0.5s;
                &:nth-of-type(1){
                    top: 0;
                    left: 0;
                    border-top: 2px solid $themeOrange;
                    border-left: 2px solid $themeOrange;
                }
                &:nth-of-type(2){
                    top: 0;
                    right: 0;
                    border-top: 2px solid $themeOrange;
                    border-right: 2px solid $themeOrange;
                }
                &:nth-of-type(3){
                    bottom: 0;
                    left: 0;
                    border-bottom: 2px solid $themeOrange;
                    border-left: 2px solid $themeOrange;
                }
                &:nth-of-type(4){
                    bottom: 0;
                    right: 0;
                    border-bottom: 2px solid $themeOrange;
                    border-right: 2px solid $themeOrange;
                }
            }
        }
        &.w_show{
            opacity: 1;
            visibility: visible;
            border: 2px solid $themeOrange;
            .w_inner-btn{
                span{
                    width: 15px;
                    height: 15px;
                    &:nth-of-type(1){
                        top: -10px;
                        left: -10px;
                    }
                    &:nth-of-type(2){
                        top: -10px;
                        right: -10px;
                    }
                    &:nth-of-type(3){
                        bottom: -10px;
                        left: -10px;
                    }
                    &:nth-of-type(4){
                        bottom: -10px;
                        right: -10px;
                    }
                }
            }
        }
    }
}

.w_error{
    color: red;
    font-weight: 700;
    label{
        color: $themeDark;
    }
}

.w_breadcrumbs{
    margin-bottom: 60px;
    display: flex;
    li{
        padding: 0 25px 0 0;
        position: relative;
        &:before{
            content: ">";
            display: block;
            position: absolute;
            left: -15px;
            font-size: 13px;
            font-weight: 600;
            color: $themeLightDark;
            top: 2px;
        }
        &:nth-of-type(1){
            &::before{
                display: none;
            }
        }
    }
    a{
        font-size: 14px;
        font-weight: 600;
        color: $themeLightDark;
        @include transition(.1s);
        &:hover{
            color: $themeDark;
        }
    }
}

.w_table{
    border-collapse: collapse;
    width: 100%;
    border: 1px solid $themeLight;
    td, th {
        // border: 1px solid $themeLight;
        text-align: left;
        padding: 10px 10px 6px 10px;
        border-bottom: 1px solid $themeLight;
        
    }
    th{
        font-size: 12px;
        font-weight: 600;
        color: $themeLightDark;
        background-color: $themeExtraLight;
        
    }
    .w_btn {
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.w_project-previous-next-nav{
    display: flex;
    border: 1px solid #F4F4F4;
    margin: 60px 0;
    li{
        width: 50%;
        a{
            display: block;
            text-align: center;
            padding: 15px 0;            
            span{
                font-size: 12px;
                font-weight: 600;
                color: $themeDark;
                position: relative;
            }
        }
        &:nth-of-type(1){
            border-left: 1px solid #F4F4F4;
            a{
                span{                    
                    &:before{
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-left: 1px solid $themeOrange;
                        border-top: 1px solid $themeOrange;
                        position: absolute;
                        top: 2px;
                        left: -17px;
                        transform: rotate(-45deg);
                        @include transition;
                    }
                }
                @media(min-width: $laptop){
                    span{
                        &:before{
                            left: -5px;
                            opacity: 0;
                        }
                    }
                    &:hover{
                        span{
                            &:before{                                
                                left: -17px;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &:nth-of-type(2){
            border-left: 1px solid #F4F4F4;
            a{
                span{
                    &:after{
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-right: 1px solid $themeOrange;
                        border-top: 1px solid $themeOrange;
                        position: absolute;
                        top: 2px;
                        right: -17px;
                        transform: rotate(45deg);
                        @include transition;
                    }
                }
                @media(min-width: $laptop){
                    span{
                        &:after{
                            right: -5px;
                            opacity: 0;
                        }
                    }
                    &:hover{
                        span{
                            &:after{
                                right: -17px;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.w_table-button-group{
    display: flex;
    .w_btn{
        margin-right: 10px;
    }
}

.clearfix{
    clear: both;
}

.w_form{
    @media(min-width: $laptop){
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;
        .w_form-group, .w_button-group{
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
        .w_form-group-6{
            width: 50%;
        }
    }    
}

.w_dashboard-gallery-container{
    .w_buttons{
        display: flex;
        justify-content: space-between;
        margin-top: -34px;
    }
}

.w_services-list-top{
    @media(min-width:$laptop){
        margin-top: 0;
    }
}