// w_page w_page-transition-exit w_page-transition-exit-active
// w_page w_page-transition-enter w_page-transition-enter-active
// w_page w_page-transition-enter-done
body{
    // min-height: 110vh;
}

.w_wrapper{
    position: relative;
}

.w_page .w_main{
    position: absolute;
    width: 100%;    
    margin-top: 80px;
    @media(min-width: $laptop){
        margin-top: 135px;
    }
}

.w_page-transition-enter {
    .w_main{
        opacity: 0;
    }
    .w_slick-menu-icon{
        opacity: 0!important;
    }
}

.w_page-transition-enter-active {
    .w_slick-menu-icon{
        transition: .4s;
        opacity: 1;
    }
}

.w_page-transition-enter-done {
    .w_main{
        transition: .4s;
        opacity: 1;
        height: calc(100vh - 135px);
    }
    .w_slick-menu-icon{
        transition: .4s;
        opacity: 1;
    }
}

.w_page-transition-exit {
    .w_main{
        opacity: 1;       
    }
    .w_mobile-nav{
        left: 50px;
        visibility: hidden;
        opacity: 0;
    }
    .w_slick-menu-icon{
        opacity: 1;
        transition: .4s;
    }   
}

.w_page-transition-exit-active {
    .w_main{
        transition: .4s;
        opacity: 0;
        height: 100vh;
         
    }
    .w_slick-menu-icon{
        transition: .3s;
        opacity: 0;
    }
}

.w_bg-image{
    @include transition(.55s);  
    transition-delay: .15s;
    visibility: hidden;
    opacity: 0;
    &.w_active{
        visibility: visible;
        opacity: 1;
    }
}

.w_animate-in{
    opacity: 0;
    visibility: hidden;
    @include transition(.75s);
    // transition-delay: .2s;
    position: relative;
    top: 0;
    &.w_animate-in-enter{
        opacity: 1;
        visibility: visible;
        top: 0;
        left: 0;
    }
    @media(min-width: $laptop){
        top: 100px;
        &.w_right-to-left{
            top: unset;
            left: 100px;
        }
        &.w_left-to-right{
            top: unset;
            left: -100px;
        }
        &.w_fade{
            top: unset;
            left: unset;
        }
        &.w_animate-in-enter{
            left: 0;
        }
    }
}